var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ position: 'relative' })},[_c('Loading',{attrs:{"active":_vm.loading,"isFullPage":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"indicator-main"},_vm._l((_vm.streakIndicators),function(indicator,index){return _c('div',{key:index,class:[
        // { 'p-col-2': !$isMobile() },
        { 'p-col-12': _vm.$isMobile() },
        'indicator',
        'p-mr-1',
        'p-mt-1',
      ],on:{"click":function($event){return _vm.indicatorClick(index, indicator.title)}}},[_c('span',{staticClass:"indicator-text"},[_vm._v(_vm._s(indicator.title)+" "),(indicator.icon != undefined)?_c('span',{class:[indicator.icon],style:({
            color:
              indicator.iconColor != undefined ? indicator.iconColor : '',
          })}):_vm._e()]),_c('span',{class:[
          'indicator-value',
          { 'indicator-green': indicator.counter == 0 },
          {
            'indicator-yellow':
              indicator.counter > 0 && indicator.counter <= 20,
          },
          { 'indicator-red': indicator.counter > 20 },
          { 'p-col-12': _vm.$isMobile() },
        ],style:({
          'background-color':
            indicator.color != undefined ? indicator.color : '',
          'color': indicator.counterColor != undefined ? indicator.counterColor : '',
        })},[_vm._v(_vm._s(indicator.counter)+" ")])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }